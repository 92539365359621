<template>
	<!-- [D] 상단 고정 메뉴일 경우 nav--fixed 클래스 추가 -->
	<nav class="nav nav--fixed">
		<div class="nav__wrap">
			<a @click="goBack" class="nav__btn nav__btn--prev">
				<i class="icon icon-left--white">{{t('10030')}}</i>
			</a>
			<p class="nav__item">
				<span class="nav__text white" v-if="room !== undefined">
				</span>
			</p>
			<a @click="openShare" class="nav__btn nav__btn--map">
				<!--<i class="icon icon-share--white"></i>-->
			</a>
		</div>
	</nav>
	<!-- //nav -->
	<div id="container" class="container">
		<div id="sub" class="sub detail">
			<!-- gallery -->
			<section class="gallery">
				<div class="gallery__slider">
					<swiper v-if="room?.images??[].length > 1" @slideChange="setSlideCurrentIndex" data-currentnum=".gallery__currentnum--1" :autoplay='{"delay": 3000, "disableOnInteraction": false}' :loop="true">
						<swiper-slide v-for="image in room?.images ?? []" :key="image.IMG_URL">
							<div class="gallery__imgbox noneimage">
								<div class="imagebg" :style="room?.images??[].length > 1 ? { backgroundImage: 'url(\'' + image.IMG_URL + '\')'} : '@/assets/images/logo_white.png center center' " />
							</div>
						</swiper-slide>
					</swiper>
					<swiper v-else @slideChange="setSlideCurrentIndex" data-currentnum=".gallery__currentnum--1" :autoplay='{"delay": 3000, "disableOnInteraction": false}' :loop="false">
						<swiper-slide v-for="image in room?.images ?? []" :key="image.IMG_URL">
							<div class="gallery__imgbox noneimage">
								<div class="imagebg" :style="{ backgroundImage: 'url(\'' + image.IMG_URL + '\')' }" />
							</div>
						</swiper-slide>
					</swiper>
				</div>
				<div class="box">
					<div class="gallery__meta gallery__meta--view">
						<span class="gallery__badge">
							<strong class="gallery__currentnum gallery__currentnum--1">{{nowSlideIndex + 1}}</strong>
							<i class="gallery__dot"></i>
							<small>{{room?.images?.length ?? 0}}</small>
						</span>
					</div>
				</div>
			</section>
			<!-- contact -->
			<section class="content">
				<div class="box">
					<div class="detail__wrap">
						<!-- 숙소 기본정보 -->
						<header class="detail__header" v-if="hotel !== undefined && hotelInfo !== undefined">
							<div class="detail__title">
								<h2 class="title ellipsis" v-if="lang === 'ko'">{{hotel.HOTEL_NM}}</h2>
								<h2 class="title ellipsis" v-else>{{hotel.HOTEL_ENM}}</h2>
								<div class="detail__slogan">
									{{room.prod_nm}}
								</div>
								<div class="detail__sns">
									<a v-if="hotelInfo.kakako_id.length > 0" :href="'https://pf.kakao.com/' + hotelInfo.kakako_id">
										<i class="icon icon-kakao">{{t('10523')}}</i>
										{{t('10524')}}
									</a>
									<a v-if="hotelInfo.tel_no.length > 0" :href="'tel:' + hotelInfo.tel_no">
										<i class="icon icon-tel"></i>{{t('10269')}}
									</a>
								</div>
							</div>
						</header>
						<!-- 숙소 상세정보 -->
						<div class="detail__body">
							<!-- 숙소 추가 정보 -->
							<article class="detail__meta">
								<ul class="search__list">
									<li>
										<router-link to="/layers/date">
											<div class="search__inner">
												<i class="icon icon-date">{{t('10586')}}</i>
												<input type="text" class="search__ipt active" readonly
													:value="dateRangeText">
											<span>{{t('10528')}}</span>
											</div>
										</router-link>
									</li>
								</ul>
							</article>
							<!-- 숙소 타입 -->
							<article class="detail__type" v-if="room !== undefined">
								<div class="type">
									<!-- [REST] 숙박 정보 표시 -->
									<ul class="type__list">
										<li v-if="room.std_dayuse !== null" v-show="roomDetail.D_SALES_YN === 'Y' && roomDetail.avail_yn_du === 'Y'">
											<div class="type__title">
												{{t('10558')}} <!-- 재번역 체크 -->
											</div>
											<div class="type__wrap">
												<div class="type__inner">
													<ul class="type__info">
														<li>
															<span>{{t('10556')}} </span>
															<strong>&nbsp;{{t('10058')}} {{room.dayuse_hours}} {{t('10059')}}</strong> <!-- 재번역 체크 -->
														</li>
														<li>
															<span>{{t('10557')}}</span>
															{{room.dayuse_stime}} ~ {{room.dayuse_etime}}
														</li>
													</ul>
												</div>
												<div class="type__price">
													<div class="info-price">
														<div class="info-price__sale" v-if="Math.ceil((room.std_dayuse - room.dayuse_amt) / room.std_dayuse * 100) > 0">
															<strong>{{Math.ceil((room.std_dayuse - room.dayuse_amt) / room.std_dayuse * 100)}}%</strong>
															<span>{{numberWithCommas(room.std_dayuse)}}</span>
														</div>
														<div class="info-price__current">
															<strong>{{numberWithCommas(room.dayuse_amt)}}</strong>{{t('10088')}}
														</div>
													</div>
												</div>
											</div>
										</li>
										<li v-show="roomDetail.S_SALES_YN === 'Y' && roomDetail.avail_yn === 'Y'">
											<div class="type__title">
												{{t('10555')}}
											</div>
											<div class="type__wrap">
												<div class="type__inner">
													<ul class="type__info">
														<li>
															<span>{{t('10279')}}</span>
															<strong>&nbsp;{{room.stay_stime}}</strong> <!-- 재번역 체크 -->
														</li>
														<li>
															<span>{{t('10280')}}</span>
															<strong>&nbsp;{{room.stay_etime}}</strong> <!-- 재번역 체크 -->
														</li>
													</ul>
												</div>
												<div class="type__price">
													<div class="info-price">
														<div class="info-price__sale" v-if="Math.ceil((room.std_amt - room.sales_amt) / room.std_amt * 100) > 0">
															<strong>{{Math.ceil((room.std_amt - room.sales_amt) / room.std_amt * 100)}}%</strong>
															<span>{{numberWithCommas(room.std_amt)}}</span>
														</div>
														<div class="info-price__current">
															<strong>{{numberWithCommas(room.sales_amt)}}</strong>{{t('10088')}}
														</div>
													</div>
												</div>
											</div>
										</li>
									</ul>
								</div>
							</article>
							<!-- 객실 정보 -->
							<!-- [REST] 관리자 페이지에서 직접 입력 -->
							<article class="detail__info" v-if="room !== undefined">
								<div class="detail__info-item">
									<h3 class="detail__subtitle" style="margin-bottom:0">
										{{t('10539')}}
									</h3>
									<div class="detail__description description">
										<div class="detail__feature">
											<span v-for="service in services" :key="service.FACI_NO">{{service.FACI_NM}}</span>
										</div>
									</div>
								</div>
								<!-- 객실 소개 -->
								<div class="detail__info-item">
									<h3 class="detail__subtitle">
										{{t('10550')}}
									</h3>
									<p class="detail__description description" v-html="room.prod_desc?.split('\n').join('<br />') ?? ''">
									</p>
								</div>
								<!-- 예약 공지 -->
								<div class="detail__info-item">
									<h3 class="detail__subtitle">
										{{t('10534')}}
									</h3>
									<p class="detail__description description" v-html="room.room_info?.split('\n').join('<br />') ?? ''">
									</p>
								</div>
								<!-- 취소 규정 -->
								<div class="detail__info-item">
									<h3 class="detail__subtitle">
										{{t('10553')}}
									</h3>
									<p v-html="room.cancel_rule" class="detail__description description" v-if="room.cancel_rule !== null">
									</p>
								</div>
							</article>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<!-- navigation -->
	<!-- [D] 대실,숙박 불가일 경우 btn-disabled 클래스 사용 -->
	<div id="navigation" class="navigation">
		<div class="btns btns--centered" v-if="roomDetail !== undefined">
			<a @click="handleClick(true)" v-if="roomDetail.avail_yn_du === 'Y' && roomDetail.D_SALES_YN === 'Y'" class="btn" :class="roomDetail.avail_yn_du === 'N' || roomDetail.D_SALES_YN === 'N' ? 'btn-disabled' : 'btn-confirm'">
				{{t('10558')}}
			</a>
			<a @click="handleClick(true)" v-else-if="roomDetail.avail_yn_du === 'N' && roomDetail.D_SALES_YN === 'Y'" class="btn" :class="roomDetail.avail_yn_du === 'N' || roomDetail.D_SALES_YN === 'N' ? 'btn-disabled' : 'btn-confirm'">
				{{t('10559')}}
			</a>
			<a @click="handleClick(false)" v-if="roomDetail.avail_yn === 'Y' && roomDetail.S_SALES_YN === 'Y'" class="btn" :class="roomDetail.avail_yn === 'N' || roomDetail.S_SALES_YN === 'N' ? 'btn-disabled' : 'btn-confirm'">
				{{t('10555')}}
			</a>
			<a @click="handleClick(false)" v-else-if="roomDetail.avail_yn === 'N' || roomDetail.S_SALES_YN === 'N'" class="btn" :class="roomDetail.avail_yn === 'N' || roomDetail.S_SALES_YN === 'N' ? 'btn-disabled' : 'btn-confirm'">
				{{t('10559')}}
			</a>
		</div>
	</div>
	<!-- //navigation -->
	<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
	<TheNavigation></TheNavigation>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import numberWithCommas from '@/utils/numberWithCommas';
import dayjs from "dayjs";
import "dayjs/locale/ko";
import ErrorAlert from "@/components/layers/Message_alert";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

dayjs.locale("ko");
export default {
	components: {
		ErrorAlert,
	},
	setup() {
		const store = useStore();
		const route = useRoute();
		const router = useRouter();
		const lang = ref();
		const nowSlideIndex = ref(0);
		const openErrorAlert = ref(false)
		const userData = computed(() => store.state.userData);
		const sessionId = computed(() => store.state.sessionId);
		const mrm02022 = computed(() => store.state.mrm02.mrm02022[hotelNo]?.[prodNo] ?? []);
		const roomDetail = computed(() => {
			const room = store.state.mrm02.rooms[hotelNo];
			if(room){
				return room.find(x => x.prod_no === prodNo);
			}
			return undefined;
		});
		const inSelectedDateRange = ref(null);

		const hotelNo = route.params.id;
		const prodNo = route.params.prodId;

		store.watch(() => [
			store.state.mhm01.selectedDateRange,
			store.state.mhm01.selectedMember,
		], ([selectedDateRange, selectedMember]) => {

			if (selectedDateRange === null) {
				inSelectedDateRange.value = {
					start: dayjs().toISOString(),
					end: dayjs().add(1, "day").toISOString(),
				}
			}else {
				inSelectedDateRange.value = selectedDateRange;
			}

			store.dispatch("mrm02/fetchRoomDetail", { 
				hotelNo, 
				arrDate: dayjs(inSelectedDateRange.value.start).format("YYYYMMDD"),
				depDate: dayjs(inSelectedDateRange.value.end).format("YYYYMMDD"),
				adultCnt: selectedMember ? selectedMember.adultCount : undefined,
				childCnt: selectedMember ? selectedMember.childCount : undefined,
				roomCnt: 1,
			});

			if(Object.keys(userData.value).length !== 0) {
				store.dispatch("mrm02/fetchRoomDetailRoom", { 
					hotelNo, 
					prodNo, 
					arrDate: dayjs(inSelectedDateRange.value.start).format("YYYYMMDD"),
					depDate: dayjs(inSelectedDateRange.value.end).format("YYYYMMDD"),
					adultCnt: selectedMember ? selectedMember.adultCount : undefined,
					childCnt: selectedMember ? selectedMember.childCount : undefined,
					roomCnt: 1,
					mem_id: userData.value.mem_id 
				});
			}else {
				store.dispatch("mrm02/fetchRoomDetailRoom", { 
					hotelNo, 
					prodNo, 
					arrDate: dayjs(inSelectedDateRange.value.start).format("YYYYMMDD"),
					depDate: dayjs(inSelectedDateRange.value.end).format("YYYYMMDD"),
					adultCnt: selectedMember ? selectedMember.adultCount : undefined,
					childCnt: selectedMember ? selectedMember.childCount : undefined,
					roomCnt: 1,
					session_id: sessionId.value 
				});
			}
		}, {immediate: true});

		onMounted(async () => {
			lang.value = store.state.app.lang;
			i18n.global.locale.value = store.state.app.lang;
			store.dispatch("mrm02/fetchMrm0201", { hotelNo });
			store.dispatch("mrm02/fetchMrm02022", { hotelNo, prodNo });
			store.dispatch("mrm02/fetchRoomMrm0204", { hotelNo, prodNo });
		});

		const setSlideCurrentIndex = (swiper) => {
			const index = swiper.realIndex;
			nowSlideIndex.value = index;
		}
		
		const room = computed(() => {
			const room = store.state.mrm02.room[hotelNo]?.[prodNo];
			//console.log(room);
			return room;
		});
		const { t }= useI18n() //번역필수 모듈

		return {
			roomDetail,
			t,  //번역필수 모듈
		  i18n,
			openErrorAlert,
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
			mrm02022,
			nowSlideIndex,
			setSlideCurrentIndex,
			goBack: () => router.back(),
			hotel: computed(() => {
				const hotels = store.state.mrm02.hotel[hotelNo];
				if(hotels && hotels.length > 0){
					return hotels[0];
				}
				return undefined;
			}),
			room,
			lang,
			hotelInfo: computed(() => {
				const hotelInfos = store.state.mrm02.mrm0201[hotelNo];
				if(hotelInfos && hotelInfos.length > 0){
					return hotelInfos[0];
				}
				return undefined;
			}),
			services: computed(() => store.state.mrm02.roomMrm0204[hotelNo]?.[prodNo] ?? []),
			dateRangeText: computed(() => (
				dayjs(inSelectedDateRange.value.start).format("MM/DD")
				+ " - " +
				dayjs(inSelectedDateRange.value.end).format("MM/DD")
				+ ", " + dayjs(inSelectedDateRange.value.end).diff(dayjs(inSelectedDateRange.value.start), "day") +" "+t('10855'))),
			isWeekend: dayjs().day() === 0 || dayjs().day() === 6,
			numberWithCommas,
			openShare: () => {
				store.dispatch("app/requestShare");
			},
			handleClick: (dayuse) => {
				if(dayuse === true) {
					try{
						//console.log(dayjs(inSelectedDateRange.value.start).diff(dayjs().startOf('day'), "day"));
						if (dayjs(inSelectedDateRange.value.start).diff(dayjs().startOf('day'), "day") === 0) {
							if(dayjs().hour() > Number(room.value.dayuse_etime.split(":")[0]) - Number(room.value.dayuse_hours)) {
								//alert("예약 가능 시간이 아닙니다.");
								store.commit("app/SET_RESMESSAGE", {res_message: null});
								store.commit("app/SET_RESMESSAGE", {res_message: "예약 가능 시간이 아닙니다."});
								openErrorAlert.value = true;
								return;
							}
						}
					}catch(e){
						//console.log(e);
						//alert("문제가 발생하였습니다.");
						store.commit("app/SET_RESMESSAGE", {res_message: null});
						store.commit("app/SET_RESMESSAGE", {res_message: "문제가 발생하였습니다."});
						openErrorAlert.value = true;
					}
					if(roomDetail.value.avail_yn_du === "Y" && roomDetail.value.D_SALES_YN === "Y" ){
						router.push(`/order?hotel_no=${hotelNo}&prod_no=${prodNo}&dayuse=${dayuse}`)
					}
					return;
				}else {
					if(dayjs(inSelectedDateRange.value.start).diff(dayjs(inSelectedDateRange.value.end), "day") === 0) {
						//alert("동일한 날짜의 숙박은 불가능합니다.");
						store.commit("app/SET_RESMESSAGE", {res_message: null});
						store.commit("app/SET_RESMESSAGE", {res_message: "동일한 날짜의 숙박은 불가능합니다."});
						openErrorAlert.value = true;
						return;
					}
					if(room.value.avail_yn === 'Y' && roomDetail.value.S_SALES_YN === "Y") {
						router.push(`/order?hotel_no=${hotelNo}&prod_no=${prodNo}&dayuse=${dayuse}`)
					}
				}
				
			}
		}
	}
}
</script>